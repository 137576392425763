import React from "react"
import {Stage, Container, withPixiApp} from "@inlet/react-pixi"
import Game from "./GamePlay"
import viewport from "../viewport"
import BackgroundGradient from "../components/BackgroundGradient"
import Snow from "../components/Snow"

const GameWithApp = withPixiApp(Game)

const GameWrapper = (props) => {
  return (
    <Stage width={viewport.width} height={viewport.height}>
      <Container>
        <BackgroundGradient />
        <GameWithApp {...props} />
        <Snow />
      </Container>
    </Stage>
  )
}

export default GameWrapper
