import React, {useState, useEffect, useRef} from "react"
import styled, {css} from "styled-components"

import GameWrapper from "./GameWrapper"
import LeaderBoard, {Button} from "./LeaderBoard"
import {H1, H3, H2, H4} from "./Text"

import {gameOverDelayTime} from "../modules/settings"
import viewport from "../viewport"
import firebase, {db, auth} from "../firebaseSetup"
import COLORS from "../styles/colors"

import Logo from "../assets/logo.png"
import MuteIcon from "../assets/mute-button.svg"
import SoundOnIcon from "../assets/sound-button.svg"

const OuterWrapper = styled.div`
  background-image: linear-gradient(${COLORS.bgPink2},${COLORS.bgPurple2});
  position: relative;
  height: 100%;
`

const InnerWrapper = styled.div`
  width: ${viewport.width}px;
  height: ${viewport.height}px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ScoreText = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`

const TextOverlay = styled.div`
  position: absolute;
  top: 48%;
  left: 50%;
  width: 90%;
  text-align: center;
  transform: translateX(-50%);
  z-index: 1;
`

const EnterScreen = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 120px;
  align-items: center;
  width: 100%;
  z-index: 10;
`

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  ${({show}) => show && css`
    opacity: 1;
  `}
`

const LogoImg = styled.img`
  width: 270px;
  margin-bottom: 20px;
`

const VideoPlayer = styled.video`
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: auto;
`

const DesktopOnly = styled.div`
  @media screen and (max-width: 420px) {
    display: none;
  }
`

const MobileAndTabletOnly = styled.div`
  display: none;
  @media screen and (max-width: 420px) {
    display: block;
  }
`

const MuteButton = styled.div`
  position: absolute;
  z-index: 20;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
`

const initialGameState = {
  isPlaying: false,
  isOver: false,
  modalOpen: false
}

let allowScoreUpdate = true

function OuterGameUI() {
  const [gameState, setGameState] = useState(initialGameState)
  const [score, setScore] = useState(0)
  const [enter, setEnter] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  const [videoFinished, setVideoFinished] = useState(false)
  const [highScores, setHighScores] = useState([])
  const videoRef = useRef(null)

  const handleResetGame = () => {
    setGameState(initialGameState)
    setScore(0)
  }

  const handleSetScore = () => {
    if (allowScoreUpdate) {
      allowScoreUpdate = false
      setScore(score + 1)
    }
    setTimeout(() => {
      allowScoreUpdate = true
    }, 50)
  }

  useEffect(() => {
    if (gameState.isOver) {
      // track game plays
      const increment = firebase.firestore.FieldValue.increment(1)
      const trackingRef = db.collection("tracking").doc("total-plays")
      trackingRef
        .update({counter: increment})
        .catch(() => {
          console.log("Error updating tracking")
        })

      setTimeout(() => {
        setGameState({
          isPlaying: false,
          isOver: false,
          modalOpen: true
        })
      }, gameOverDelayTime)
    }
  }, [gameState.isOver])

  const handleSetHighScores = (newEntry) => {
    setHighScores((oldScores) => [...oldScores, newEntry]
      .sort((a, b) => b.score - a.score)
      .slice(0, 5)
    )
  }

  const handleSubmitScore = (newEntry) => {
    const leaderRef = db.collection("leaderboard")
    return leaderRef
      .doc().set(newEntry)
      .then(() => {
        handleSetHighScores({...newEntry, newScore: true})
        return true
      })
  }

  const videoHasEnded = () => {
    setVideoFinished(true)
  }

  const handleMuteButton = () => {
    setIsMuted(!isMuted)
    if (videoRef.current.muted) {
      videoRef.current.muted = false
    } else {
      videoRef.current.muted = true
    }
  }

  useEffect(() => {
    const currentVideo = videoRef.current
    currentVideo.addEventListener("ended", videoHasEnded, false)
    currentVideo.addEventListener("webkitendfullscreen", videoHasEnded, false)
    const increment = firebase.firestore.FieldValue.increment(1)
    const trackingRef = db.collection("tracking").doc("unique-plays")
    trackingRef
      .update({counter: increment})
      .catch(() => {
        console.log("Error updating tracking")
      })

    auth.signInAnonymously().then(() => {
      db.collection("leaderboard")
        .orderBy("score", "desc").limit(5)
        .get()
        .then((snapshot) => {
          snapshot.forEach((doc) => {
            handleSetHighScores(doc.data())
          })
        })
        .catch(() => {
          console.log("Error getting documents")
        })
    })

    return function() {
      currentVideo.removeEventListener("ended", videoHasEnded)
      currentVideo.removeEventListener("webkitendfullscreen", videoHasEnded)
    }
  }, [])

  return (
    <OuterWrapper>
      <InnerWrapper>
        {
          !enter &&
          <EnterScreen>
            <LogoImg src={Logo} alt="Logo" />
            <Button onClick={() => {
              setEnter(true)
              videoRef.current.play()
            }}>
              <H4>Enter</H4>
            </Button>
          </EnterScreen>
        }
        {
          !videoFinished &&
          <VideoWrapper show={enter}>
            <VideoPlayer ref={videoRef}>
              <source src="/intro.mp4" type="video/mp4" />
            </VideoPlayer>
            <MuteButton onClick={handleMuteButton}>
              {isMuted
                ? <img src={MuteIcon} alt="" />
                : <img src={SoundOnIcon} alt="" />
              }
            </MuteButton>
          </VideoWrapper>
        }
        {
          enter && videoFinished &&
          <ScoreText>
            <H1>{score}</H1>
          </ScoreText>
        }
        {
          gameState.isOver && !gameState.modalOpen &&
          <TextOverlay>
            <H2>Game Over</H2>
          </TextOverlay>
        }
        {
          videoFinished && !gameState.isOver && !gameState.isPlaying && !gameState.modalOpen &&
          <TextOverlay>
            <DesktopOnly>
              <H3>Space bar or click to jump</H3>
            </DesktopOnly>
            <MobileAndTabletOnly>
              <H3>Tap to jump</H3>
            </MobileAndTabletOnly>
          </TextOverlay>
        }
        {
          gameState.modalOpen &&
          <LeaderBoard
            highScores={highScores}
            score={score}
            handleSubmitScore={handleSubmitScore}
            handleResetGame={handleResetGame} />
        }
        <GameWrapper
          hasEntered={enter}
          handleSetScore={handleSetScore}
          setGameState={setGameState}
          gameState={gameState} />
      </InnerWrapper>
    </OuterWrapper>
  )
}

export default OuterGameUI
