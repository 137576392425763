import styled, {css} from "styled-components"
import COLORS, {colorFinder} from "../styles/colors"

const base = 18
export const rem = (px) => `${px / base}rem`

const textBase = css`
  position: relative;
  color: ${COLORS.white};
  font-weight: normal;
  ${(props) =>
    props.color &&
    css`
      color: ${colorFinder(props.color)};
    `}
`

// Score
export const H1 = styled.h1`
  ${textBase};
  font-size: ${rem(40)};
  line-height: ${rem(35)};
`

// Game Over
export const H2 = styled.h2` 
  ${textBase};
  font-size: ${rem(36)};
  line-height: ${rem(35)};
`

// Leaderboard text
export const H3 = styled.h3`
  ${textBase};
  font-size: ${rem(20)};
  line-height: ${rem(35)};
`

// Buttons and links
export const H4 = styled.h4`
  ${textBase};
  font-size: ${rem(15)};
  line-height: ${rem(35)};
`
