import React, {useState, useEffect} from "react"
import styled, {css} from "styled-components"
import {H3, H4, H2} from "./Text"
import COLORS, {cssColor, hexToRGBA} from "../styles/colors"

import MessengerImg from "../assets/icons/messenger.svg"
import ChainsImg from "../assets/icons/link.svg"
import LinkedInImg from "../assets/icons/linkedin.svg"
import WhatsAppImg from "../assets/icons/whatsapp.svg"

const Wrapper = styled.div`
  position: absolute;
  width: 82%;
  height: 100%;
  top: 0%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 110px 0 20px 0;
`

export const Button = styled.div`
  cursor: pointer;
  margin: 5px 0;
  border-radius: 5px;
  padding: 2px 50px;
  font-size: 22px;
  text-decoration: none;
  position: relative;
  display: inline-block;
  background-color: ${COLORS.pink};
  box-shadow: 0px 5px 0px 0px ${COLORS.darkPink};
  &:active {
    transform: translate(0px, 5px);
    -webkit-transform: translate(0px, 5px);
    box-shadow: 0px 1px 0px 0px ${COLORS.darkPink};
  }
  &:hover {
    background-color: ${COLORS.lightPink};
  }
`

export const UnderlinedButton = styled.div`
  cursor: pointer;
  padding: 5px;
  margin-top: 20px;
  h4 {
    line-height: 25px;
    border-bottom: 1px solid ${COLORS.white};
  }

`

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 10px 0;
  width: 100%;
`

const ShareIcons = styled.div`
  padding: 10px 0;
  display: flex;
  width: 260px;
  justify-content: space-evenly;
`

const ScoreRow = styled.div`
  min-height: 35px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid white;
  margin: 2px 0;
  ${({newScore}) => newScore && css`
    h3 {
      color: ${COLORS.yellow};
    }
  `}
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  text-align: center;
  h2 {
    padding-bottom: 15px;
  }
`

const NeverblandLink = styled.div`
  height: 40px;
  padding-bottom: 10px;
  h3 {
    line-height: 25px;
    border-bottom: 1px solid ${COLORS.white};
  }
`

const NameInput = styled.input`
  background: transparent;
  border: none;
  border-bottom: 2px solid white;
  margin-bottom: 50px;
  display: inline-block;
  color: white;
  outline: none;
  font-size: 70px;
  text-transform: uppercase;
  width: 220px;
  letter-spacing: 20px;
  user-select: all;
  :focus {
    background: ${cssColor(hexToRGBA(COLORS.pink, 0.3))};
  }
`

const NameInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  h3 {
    text-align: center;
    margin-bottom: 10px;
  }
`

const PlayButtonWrapper = styled.div`
  padding: 20px 40px;
`

const SuccessfullyCopied = styled.div`
  position: absolute;
  top: 50%;
  left: 110%;
  transform: translateY(-50%);
`

const IconWrapper = styled.div`
  position: relative;
  width: 51px;
  height: 51px;
  padding: 3px;
  a {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
  img {
    cursor: pointer;
  }
`

const URL = window.location.href
const URL_ENCODED = encodeURIComponent(URL)
const SHARE_TEXT = "Play Santa's Blotto!"

const Leaderboard = (
  {handleResetGame, handleSubmitScore, highScores, score}
) => {
  const [shouldAdd, setShouldAdd] = useState(false)
  const [copied, setCopied] = useState(false)
  const [successAdded, setSuccessAdded] = useState(false)
  const [errorOnAdd, setErrorOnAdd] = useState(false)
  const [name, setName] = useState("")

  const handleInput = (e) => {
    if (name.length < 4) {
      setName((`${e.target.value}`).toUpperCase())
    }
  }

  const reHandleSubmitScore = () => {
    const newEntry = {
      name,
      score
    }
    if (name.length > 0) {
      handleSubmitScore(newEntry)
        .then((isSuccess) => {
          if (isSuccess) {
            setShouldAdd(false)
            setSuccessAdded(true)
            setErrorOnAdd(false)
          }
        })
        .catch(() => {
          setErrorOnAdd(true)
        })
    }
  }

  const isScoreHighEnough = () => {
    if (highScores.length < 5 || (highScores[4] && score > highScores[4].score)) return true
    return false
  }

  useEffect(() => {
    if (!successAdded && isScoreHighEnough()) {
      setShouldAdd(true)
    }
  }, [])

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  return (
    <Wrapper>
      {shouldAdd ?
        <NameInputWrapper>
          <H3>Add your name into the leaderboard</H3>
          <NameInput
            maxLength="3"
            onChange={handleInput}
            value={name}
            type="text" />
          {errorOnAdd &&
            <H4>There was an error submitting your score</H4>
          }
          <Button onClick={reHandleSubmitScore}>
            <H4>{errorOnAdd ? "Try again" : "Submit"}</H4>
          </Button>

          {errorOnAdd &&
            <UnderlinedButton onClick={() => setShouldAdd(false)}>
              <H4>Continue without submit</H4>
            </UnderlinedButton>
          }
        </NameInputWrapper>
        :
        <>
          <InnerWrapper>
            <H2>High Scores</H2>
            <ScoreWrapper>
              {
                highScores.map(((highScore, i) =>
                  <ScoreRow key={`score-row-${i}`} newScore={highScore.newScore}>
                    <H3>{`${i + 1}. ${highScore.name}`}</H3>
                    <H3>{highScore.score}</H3>
                  </ScoreRow>
                ))
              }
            </ScoreWrapper>
          </InnerWrapper>
          <PlayButtonWrapper onClick={handleResetGame}>
            <Button>
              <H4>Play again</H4>
            </Button>
          </PlayButtonWrapper>
        </>
      }

      <ShareIcons>
        <IconWrapper>
          <a href={`fb-messenger://share/?link=${URL_ENCODED}%2Fsend-dialog`} target="_blank" rel="noopener noreferrer">
            <img src={MessengerImg} alt="" />
          </a>
        </IconWrapper>
        <IconWrapper>
          <a
            href={`https://www.linkedin.com/shareArticle?mini=true&url=${URL}&title=${SHARE_TEXT}`}
            target="_blank"
            rel="noopener noreferrer">
            <img src={LinkedInImg} alt="" />
          </a>
        </IconWrapper>
        <IconWrapper>
          <a href={`whatsapp://send?text=${URL}`} data-action="share/whatsapp/share">
            <img src={WhatsAppImg} alt="" />
          </a>
        </IconWrapper>
        <IconWrapper onClick={() => {
          setCopied(true)
          const textArea = document.createElement("textarea")
          document.body.appendChild(textArea)
          textArea.value = URL
          textArea.select()
          document.execCommand("copy")
          document.body.removeChild(textArea)
        }}>
          <img
            src={ChainsImg}
            alt="" />
          {copied &&
          <SuccessfullyCopied>
            <H4>Copied!</H4>
          </SuccessfullyCopied>}
        </IconWrapper>
      </ShareIcons>

      <NeverblandLink>
        <a href="https://neverbland.com?from=santasblotto.com" target="_blank" rel="noopener noreferrer">
          <H3>neverbland.com</H3>
        </a>
      </NeverblandLink>
    </Wrapper>
  )
}

export default Leaderboard
