import {boxBox} from "intersects"
import PIXI from "../pixiSettings"
import deadSantaImg from "../assets/dead-santa.png"

export function boxCollision(spr, trgt) {
  const intersected = boxBox(spr.x + 24, spr.y + 10, spr.w - 30, spr.h - 35, trgt.x, trgt.y, trgt.w, trgt.h)
  return intersected
}

export function spriteTextures() {
  const images = ((ctx) => ctx.keys().map(ctx))(require.context("../assets/sprites", true, /.*/))
  return images.map((image) => new PIXI.Texture.from(image))
}

export function spriteUpTextures() {
  const images = ((ctx) => ctx.keys().map(ctx))(require.context("../assets/sprites-up", true, /.*/))
  return images.map((image) => new PIXI.Texture.from(image))
}

export function deadSantaTextures() {
  return [new PIXI.Texture.from(deadSantaImg)]
}

export function randomIntBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}
