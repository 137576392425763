import {
  pipeSpacing,
  initialEmptyPipeSpace,
  minHalfGapSize,
  maxHalfGapSize,
  minGapProxToEdge,
  maxGapProxToEdge,
  caneHeight,
  caneWidth
} from "./settings"

import {randomIntBetween} from "./helpers"

let prevGapPosition = 0
let prevHalfGapSize = 0

export function generateCane(i, leadSpace, newPipe) {
  const isTopCane = i % 2 === 0
  const randHalfGapSize = randomIntBetween(minHalfGapSize, maxHalfGapSize)
  const randGapPositon = randomIntBetween(minGapProxToEdge, maxGapProxToEdge)

  if (isTopCane) {
    prevGapPosition = randGapPositon
    prevHalfGapSize = randHalfGapSize
  }
  const fixXDistance = newPipe ? -2 : 0
  return {
    x: isTopCane
      ? leadSpace + (i * pipeSpacing)
      : leadSpace + fixXDistance + ((i - 1) * pipeSpacing),
    y: isTopCane
      ? (randGapPositon - randHalfGapSize) - caneHeight
      : prevGapPosition + prevHalfGapSize,
    w: caneWidth,
    h: caneHeight,
    position: isTopCane ? randGapPositon : prevGapPosition,
    gapSize: isTopCane ? randHalfGapSize : prevHalfGapSize,
    caneOrient: isTopCane ? 0 : 1,
    index: randomIntBetween(0, 3)
  }
}

export const generateInitialPipes = () =>
  [...new Array(6)].map((_, i) =>
    generateCane(i, initialEmptyPipeSpace, false))

export function generateCanePair(leadSpace) {
  return [
    generateCane(0, leadSpace),
    generateCane(1, leadSpace)
  ]
}
