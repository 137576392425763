import React from "react"

import {Graphics} from "@inlet/react-pixi"
import * as PIXI from "pixi.js"
import COLORS from "../styles/colors"

import viewport from "../viewport"

function gradient(from, to, width, height) {
  const c = document.createElement("canvas")
  c.width = width
  c.height = height
  const ctx = c.getContext("2d")
  const grd = ctx.createLinearGradient(0, 0, 0, height)
  grd.addColorStop(0, from)
  grd.addColorStop(1, to)
  ctx.fillStyle = grd
  ctx.fillRect(0, 0, width, height)

  return new PIXI.Texture.from(c)
}

const BackgroundGradient = () => {
  return (
    <Graphics
      preventRedraw
      draw={(g) => {
        g.beginTextureFill(gradient(COLORS.bgPurple, COLORS.bgPink, viewport.width, viewport.height))
        g.drawRect(0, 0, viewport.width, viewport.height)
      }} />
  )
}


export default BackgroundGradient
