export function getDimensions() {
  let width = 420
  let height = 650

  if (window.innerWidth < width) {
    width = window.innerWidth
    height = window.innerHeight
  }
  // if (window.innerHeight < height) {
  //   height = window.innerHeight
  // }

  return {height, width}
}

export default getDimensions()
