import viewport from "../viewport"

// -- Game Settings --
export const gameSpeed = 3
export const gravityStrength = -1.2
export const terminalVelocity = -14
export const jumpVelocity = 18

const fifthOfWidth = Math.floor((viewport.width / 5))
export const spriteXPosition = fifthOfWidth % 0 ? fifthOfWidth : fifthOfWidth - 1

// milliseconds
export const gameOverDelayTime = 1500

export const pipeSpacing = 120
export const initialEmptyPipeSpace = 400
export const halfScreen = (viewport.height / 2)

export const leadSpacing = 680
// candy cane png dimensions
export const caneHeight = 700
export const caneWidth = 38

export const minHalfGapSize = 130
export const maxHalfGapSize = 180
export const minGapProxToEdge = viewport.height / 4
export const maxGapProxToEdge = viewport.height - minGapProxToEdge

export const initialSprite = {
  x: spriteXPosition,
  y: halfScreen - 120,
  w: 70,
  h: 110
}
