import React from "react"
import "./firebaseSetup"
import ReactDOM from "react-dom"
import GlobalStyle from "./styles/global"
import Game from "./components/OuterGameState"


const App = () => {
  return (
    <>
      <GlobalStyle />
      <Game />
    </>
  )
}


ReactDOM.render(<App />, document.getElementById("root"))
