import React from "react"

import * as PIXI from "pixi.js"
import {PixiComponent, Sprite, Container} from "@inlet/react-pixi"

import cane1 from "../assets/canes/canes-1.png"
import cane2 from "../assets/canes/canes-2.png"
import cane3 from "../assets/canes/canes-3.png"
import cane4 from "../assets/canes/canes-4.png"

const canes = [
  cane1,
  cane2,
  cane3,
  cane4
]

export const Rectangle = PixiComponent("Rectangle", {
  create: () => new PIXI.Graphics(),
  applyProps: (instance, _, props) => {
    const {x, y, width, height} = props

    instance.clear()
    instance.beginFill(0xFFFFFF)
    instance.alpha = 0
    instance.drawRect(x, y, width, height)
    instance.endFill()
  }
})

const CandyCane = ({x, y, width, height, index}) => {
  return (
    <Container
      x={x}
      y={y}
      width={width}
      height={height}>
      <Sprite
        image={canes[index]}
        anchor={[0, 0]}
        x={0}
        y={0}
        width={width}
        height={height}
        rotation={0} />
    </Container>
  )
}
export default CandyCane
