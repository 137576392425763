import React from "react"
import {Sprite, Container} from "@inlet/react-pixi"

import viewport from "../viewport"

import houses from "../assets/houses.png"
import towers from "../assets/towers.png"

const viewportWidth = 420
const bgHeight = 1043
const bgWidth = viewportWidth * 3

const BackgroundSegment = ({moveLeft}) => {
  return (
    <>
      <Sprite
        image={towers}
        anchor={[0, 0]}
        x={moveLeft}
        y={0}
        width={bgWidth}
        height={bgHeight}
        rotation={0} />
      <Sprite
        image={houses}
        anchor={[0, 0]}
        x={(moveLeft * 2)}
        y={0}
        width={(bgWidth * 2) + 4}
        height={bgHeight}
        rotation={0} />
    </>
  )
}

const segments = [...new Array(18)]

const LondonBackground = ({x}) => {
  const moveLeft = x % bgWidth

  return (
    <Container
      x={0}
      y={viewport.height - bgHeight}
      width={viewport.width}
      height={bgHeight}>
      {
        segments.map(((_, i) =>
          <BackgroundSegment key={`segment${i}`} moveLeft={moveLeft + ((i * bgWidth))} />
        ))
      }
    </Container>
  )
}

export default LondonBackground
