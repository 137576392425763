import React, {useState} from "react"
import {Container, Sprite, useTick} from "@inlet/react-pixi"

import {randomIntBetween} from "../modules/helpers"
import viewport from "../viewport"

import snow2 from "../assets/snowflakes/snow2.png"
import snow3 from "../assets/snowflakes/snow3.png"
import snow4 from "../assets/snowflakes/snow4.png"
import snow5 from "../assets/snowflakes/snow5.png"
// const SnowTexture = new PIXI.Texture.from(image)

const chooseSnowFlake = (size) => {
  switch (size) {
    case 2: return snow2
    case 3: return snow3
    case 4: return snow4
    case 5: return snow5
    default: return snow3
  }
}

const TextureSnowFlake = ({x, y, size, alpha}) => {
  return (
    <Container
      x={x}
      y={y}
      width={size}
      height={size}>
      <Sprite
        image={chooseSnowFlake(size)}
        anchor={[0, 0]}
        alpha={alpha}
        x={0}
        y={0}
        width={size}
        height={size}
        rotation={0} />
    </Container>
  )
}

// const Snowflake = ({x, y, size, alpha}) => {
//   return (
//     <Graphics
//       draw={(g) => {
//         g.clear()
//         g.lineStyle(0)
//         g.beginFill(0xffffff, alpha * 0.1)
//         g.drawCircle(x, y, size)
//         g.endFill()
//       }} />)
// }
function generateSnowFlakeBatch() {
  return [...new Array(10)]
    .map(() => {
      return {
        x: randomIntBetween(3, viewport.width),
        y: 0,
        size: randomIntBetween(2, 5),
        xSpeed: randomIntBetween(0, 2),
        ySpeed: randomIntBetween(5, 15),
        alpha: randomIntBetween(2, 7) * 0.1
      }
    })
}

const initialSnowFlakes = generateSnowFlakeBatch()

let counter = 0

const Snow = () => {
  const [snowFlakes, setSnowFlakes] = useState(initialSnowFlakes)

  useTick(() => {
    counter++

    const existingSnowflakes = snowFlakes
      .filter((snowFlake) => {
        if (snowFlake.y > viewport.height || snowFlake.x < 0) return false
        return true
      })
      .map((snowFlake) => ({
        ...snowFlake,
        x: snowFlake.x + (snowFlake.xSpeed * 0.4),
        y: snowFlake.y + (snowFlake.ySpeed * 0.1)
      }))
    let newSnowFlakes = []
    if (counter % 120 === 0) {
      newSnowFlakes = generateSnowFlakeBatch()
    }
    setSnowFlakes([...existingSnowflakes, ...newSnowFlakes])
  })

  return (
    <>
      {
        snowFlakes.map((snowFlake, i) =>
          <TextureSnowFlake key={`snowflake-${snowFlake.x}-${snowFlake.y}-${i}`} {...snowFlake} />
          // <Snowflake key={`snowflake-${snowFlake.x}-${snowFlake.y}-${i}`} {...snowFlake} />
        )
      }
    </>
  )
}


export default Snow
