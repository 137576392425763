import React from "react"
import Canes from "./Rectangle"

const Pipes = ({pipes}) => {
  return (
    <>
      {pipes.map(({x, y, w, h, index}) =>
        <Canes key={`rect${x}${y}${h}`} x={x} y={y} width={w} height={h} fill={0x358135} index={index} />
      )}
    </>
  )
}

export default Pipes
