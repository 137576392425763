import {css} from "styled-components"

export const loadFonts = css`
  @font-face {
    font-family: 'Cooper_Regular';
    src: url('./fonts/Cooper_Regular.ttf') format('truetype');
    font-style: normal;
  }
`

export const base = 18
export const baseSmall = 16

export default {
  cooper: "Cooper_Regular, sans-serif"
}
