import * as firebase from "firebase/app"
import "firebase/analytics"
import "firebase/auth"
import "firebase/firestore"

const configStaging = {
  apiKey: "AIzaSyAMqC8Qn6F1POHnCVWRJuMAqj_KN4OFrb8",
  authDomain: "xmas-flappy-bird.firebaseapp.com",
  databaseURL: "https://xmas-flappy-bird.firebaseio.com",
  projectId: "xmas-flappy-bird",
  storageBucket: "xmas-flappy-bird.appspot.com",
  messagingSenderId: "1099244828960",
  appId: "1:1099244828960:web:ed7db86dc006971d497f45",
  measurementId: "G-5F6NQXPGE4"
}

const configProduction = {
  // ---- TODO update for production ----
}

const config = process.env.REACT_APP_BUILD_ENV === "prod"
  ? configProduction : configStaging

// Initialize Firebase
firebase.initializeApp(config)
firebase.analytics()

export const db = firebase.firestore()
export const auth = firebase.auth()
export default firebase
