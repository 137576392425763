import React, {useEffect, useState, useMemo} from "react"
import {AnimatedSprite, useTick, Container} from "@inlet/react-pixi"

import Pipes from "./Pipes"
import {
  boxCollision,
  spriteTextures,
  // spriteUpTextures,
  deadSantaTextures} from "../modules/helpers"
import {Rectangle} from "./Rectangle"
import LondonBackground from "./LondonBackground"

import {
  gameSpeed,
  gravityStrength,
  terminalVelocity,
  jumpVelocity,
  initialSprite,
  spriteXPosition,
  leadSpacing
} from "../modules/settings"

import {generateInitialPipes, generateCane} from "../modules/pipe-generation"
import viewport from "../viewport"

const initialPipes = generateInitialPipes()
const animatedSprites = spriteTextures()
// const animatedUpSprites = spriteUpTextures()
const deadSantaSprites = deadSantaTextures()

let vel = 0
let counter = 0

const GamePlay = ({setGameState, gameState, handleSetScore, hasEntered}) => {
  const [positions, setPositions] = useState({
    pipes: initialPipes,
    sprite: initialSprite
  })

  const {isPlaying, isOver, modalOpen} = gameState

  const spaceBarPress = (event) => {
    if (event.keyCode === 32) {
      handleJumpSprite()
    }
  }

  const handleJumpSprite = () => {
    vel = jumpVelocity
    if (!modalOpen && !isOver && !isPlaying) { // start game
      setGameState({
        isPlaying: true,
        isOver: false,
        modalOpen: false
      })
    }
  }

  const updatePostionDeadSanta = () => {
    setPositions((oldPositions) => ({
      ...oldPositions,
      sprite: {
        ...oldPositions.sprite,
        y: oldPositions.sprite.y - 1
      }
    }))
  }

  const updatePosition = (newPipes, firstPipe) => {
    const firstPipeOffScreen = firstPipe.x < -30

    const newSprite = {
      ...positions.sprite,
      y: positions.sprite.y - vel
    }

    if (firstPipeOffScreen) {
      const updatedNewPipes = newPipes
        .filter((_, i) => i)
        .concat(generateCane(firstPipe.caneOrient, leadSpacing, true))

      setPositions({
        pipes: updatedNewPipes,
        sprite: newSprite
      })
    } else {
      setPositions({
        pipes: newPipes,
        sprite: newSprite
      })
    }
  }

  const checkCollision = (newPipePosition) => {
    if (
      positions.sprite.y >= (viewport.height - (positions.sprite.h - 20))
      || positions.sprite.y < -20
    ) return true

    return newPipePosition.reduce((current, pipe) => {
      if (!current) return boxCollision(positions.sprite, pipe)
      return current
    }, false)
  }

  const restartGame = () => {
    vel = 0
    counter = 0
    setPositions({
      pipes: generateInitialPipes(),
      sprite: initialSprite
    })
  }

  useTick(() => {
    if (isPlaying) {
      counter++
      const newPipes = positions.pipes.map((pipe) => ({...pipe, x: pipe.x - gameSpeed}))
      const collision = checkCollision(newPipes)

      const firstPipe = positions.pipes[0]

      if (
        firstPipe.x === spriteXPosition ||
        firstPipe.x === spriteXPosition - 1 ||
        firstPipe.x === spriteXPosition - 2) {
        handleSetScore()
      }

      if (collision) {
        setGameState({ // stop game
          isPlaying: false,
          isOver: true,
          modalOpen: false
        })
      }

      if (isPlaying) {
        updatePosition(newPipes, firstPipe)
      }

      // accelerate velocity
      if (vel > terminalVelocity) {
        vel += gravityStrength
      }
    }
    if (isOver) {
      updatePostionDeadSanta()
    }
  })

  useEffect(() => {
    if (!isPlaying && !isOver && !modalOpen) {
      restartGame()
    }
  }, [isPlaying, isOver, modalOpen])


  useEffect(() => {
    document.addEventListener("keydown", spaceBarPress, false)
    return () => {
      document.removeEventListener("keydown", spaceBarPress, false)
    }
  })

  const setWhichAnimation = () => {
    if (isOver) return deadSantaSprites
    // if (vel > 0) return animatedUpSprites
    return animatedSprites
  }

  const setFrame = () => {
    if (isOver) return 0
    // if (vel > 0) {
    //   return Math.floor(counter / 9) % 3
    // }
    return Math.floor(counter / 3) % 12
  }

  const {y, x, w, h} = positions.sprite

  return useMemo(() => {
    if (!hasEntered) {
      return null
    }
    return (
      <>
        <LondonBackground x={0 - counter} />
        {!gameState.modalOpen &&
          <Pipes pipes={positions.pipes} />
        }
        {!gameState.modalOpen &&
          <AnimatedSprite
            textures={setWhichAnimation()}
            isPlaying
            initialFrame={setFrame()}
            animationSpeed={0.4}
            anchor={[0, 0]}
            x={x}
            y={y}
            width={w}
            height={h}
            rotation={0} />
        }
        <Container
          interactive
          pointerdown={handleJumpSprite}>
          <Rectangle
            x={0}
            y={0}
            width={viewport.width}
            height={viewport.height} />
        </Container>
      </>
    )
  }, [positions, gameState.modalOpen, hasEntered])
}

export default GamePlay
